@import "./mdui.extension.scss";
@import "./mdui.change.scss";
@import "./virtualScroll.scss";
@import "./lite-mode.scss";

// ==========

@font-face {
    font-family: Minecraft;
    src: url(/fonts/Mojangles.ttf) format("truetype");
}

.minecraft-font {
    font-family: Minecraft !important;
}

// ==========

@media (min-width: 600px) {
    .mdui-row .retract {
        width: 30px;
    }
}

// ==========

*::-webkit-scrollbar {
    display: none;
}

body.loading {
    .mdui-appbar {
        -webkit-box-shadow: none !important;
                box-shadow: none !important;
    }
    
    .loading-box {
        display: block;
        width: 100%;
    }
}

body {
    padding-top: 120px;
    transition: padding-top .3s ease-in-out !important;
    
    &:not(.loading) .loading-box {
        display: none !important;
    }
}

// ==========

#list {
    .mdui-list-item {
        height: 72px;
    }
    
    .mdui-list-item-content {
        .mdui-list-item-title:empty {
            display: none !important;
        }
        
        .mdui-list-item-text:empty {
            display: none !important;
        }
    }
}


#extensions-vlist {
    &::-webkit-scrollbar {
        display: none !important;
    }
    
    .virtual-scroll__item:not(:last-child) {
        border: 0;
        border-bottom: 1px solid #d0d0d5;
    }
}
