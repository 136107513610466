.virtual-scroll {
    overflow: auto;
    position: relative;
    
    &::-webkit-scrollbar {
        display: block !important;
        width: 2px;
        height: 0;
    }
    
    // &__container {}
    
    &__item {
        position: absolute !important;
        left: 0;
        right: 0;
    }
}
