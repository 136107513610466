@mixin lite-mode() {
    padding-top: 45px;
    padding-bottom: 40px;
    
    .minecraft-font {
        font-family: Roboto, Noto, Helvetica, Arial, sans-serif !important;
    }
    
    .mdui-fab {
        bottom: 56px;
    }
    
    #header {
        & > .mdui-toolbar {
            height: 40px !important;
            bottom: 0 !important;
            top: auto !important;
            position: fixed;
            
            .mdui-textfield-input {
                font-size: 13px;
                padding: 0 !important;
                border: 0 !important;
                margin: 0 !important;
            }
            
            .mdui-icon {
                display: none;
            }
            
            .mdui-textfield {
                padding: 8px 0 !important;
            }
            
            .mdui-textfield-focus .mdui-textfield-input,
            .mdui-textfield-focus .mdui-textfield-input:hover,
            .mdui-textfield-input {
                box-shadow: 0 0 0 0 !important;
            }
        }
        
        .mdui-collapse-item {
            & > .mdui-toolbar {
                height: 45px !important;
                
                .mdui-list-item-content {
                    margin: 0 12px;
                }
            }
            
            .mdui-collapse-item-body {
                .mdui-toolbar {
                    height: 45px !important;
                }
            }
        }
    }
    
    #grammar {
        font-size: 13px;
    }
    
    #note {
        font-size: 11px;
        margin-top: 0;
    }
    
    #list {
        .mdui-list-item {
            padding-right: 8px;
            padding-left: 8px;
            min-height: 24px;
            max-height: 36px;
            // height: calc(100vh / 16);
        }
        
        .mdui-list-item-content {
            padding: 0;
            height: 20px;
            
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y: hidden;
            
            .mdui-list-item-title {
                display: inline;
                font-size: 13px;
            }
            
            .mdui-list-item-text {
                display: inline;
                font-size: 11px;
            }
        }
    }
    
    .mdui-dialog-full-screen {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100% !important;
        width: 100% !important;
        margin: 0 !important;
        border-radius: 0 !important;
    }
}

body.lite-mode {
    @include lite-mode()
}
